import React from "react";
import styled from "styled-components";

import Header from "./header";
import MainInfo from "./main-info";

const LandingPage = ({ state }) => {
  return (
    <LandingPageWrapper>
      <Header />
      <MainInfo />
    </LandingPageWrapper>
  );
};

export default LandingPage;

const LandingPageWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
