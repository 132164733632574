import React, { useState } from "react";
import Modal from "react-modal";
import InputMask from "react-input-mask";
import emailjs from "emailjs-com";
import styled from "styled-components";

import Input from "./input";

import closeIcon from "../../icons/close.svg";

const customStyles = {
  overlay: {
    background: "rgb(0 0 0 / 0.63)",
  },
  content: {
    width: "380px",
    background: "#rgb(255 255 255 / 0%)",
    color: "white",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement("#root");

const CustomModal = ({ button: Button }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const changeName = (e) => {
    setName(e.target.value);
  };

  const changePhone = (e) => {
    setPhone(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "yandex",
        "maksium_2020",
        e.target,
        "user_NUzOrFfMpsDfqyakNd8Kh"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setIsOpen(false);
  };

  return (
    <>
      <Button onClick={openModal} />
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <CloseIcon src={closeIcon} onClick={closeModal} />
        <ModalTitle>Оставьте свой номер телефона и мы перезвоним</ModalTitle>

        <Form onSubmit={onSubmit}>
          <Input label="Имя" value={name} onChange={changeName} name={"name"} />
          <InputMask
            mask="+375 (99) 999-99-99"
            value={phone}
            onChange={changePhone}
          >
            {(inputProps) => (
              <Input {...inputProps} label="Телефон" name={"phone"} />
            )}
          </InputMask>
          <SubmitButton>Отправить</SubmitButton>
        </Form>
      </Modal>
    </>
  );
};

const ModalTitle = styled.div`
  font-size: 24px;
`;

const CloseIcon = styled.img`
  height: 16px;
  float: right;

  &:hover {
    cursor: pointer;
  }
`;

const Form = styled.form`
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  background: #ffcb00;
  border-radius: 200px;
  color: white;
  border: none;
  padding: 10px 20px;

  &:hover {
    cursor: pointer;
  }
`;

export default CustomModal;
