import React from "react";

export default (props) => (
  <svg
    width="221"
    height="158"
    viewBox="0 0 221 158"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5739 155.077C15.5739 155.077 84.1898 157.464 136.296 130.526C137.715 129.829 138.361 127.904 137.682 126.4C136.43 123.249 134.143 117.712 131.84 110.988C126.616 95.5027 15.5739 155.077 15.5739 155.077Z"
      fill="#A9CBD3"
    />
    <path
      d="M131.964 110.973C133.324 115.036 134.777 118.824 135.874 121.73C84.1701 150.997 15.5584 154.947 15.5584 154.947C15.5584 154.947 126.616 95.5026 131.964 110.973Z"
      fill="#8CB8BF"
    />
    <path
      d="M113.008 45.3669C113.008 45.3669 116.206 76.4098 126.12 91.3366C136.033 106.263 43.4009 153.346 15.6972 155.063C15.6972 155.063 49.4547 116.976 66.8666 87.7022C84.2784 58.4286 113.008 45.3669 113.008 45.3669Z"
      fill="#CCE4EA"
    />
    <path
      opacity="0.5"
      d="M125.996 91.3513C135.91 106.278 43.2776 153.361 15.5739 155.077C57.7349 135.002 111.547 75.1165 111.547 75.1165C110.04 59.32 112.885 45.3816 112.885 45.3816C112.885 45.3816 116.083 76.4244 125.996 91.3513Z"
      fill="#A9CBD3"
    />
    <path
      d="M143.508 50.3148C146.408 50.4974 159.768 51.2822 173.605 50.8218C194.568 50.3045 212.011 44.3971 210.364 50.5348C208.887 55.9921 180.559 93.4322 136.225 117.331C91.8919 141.231 34.6242 154.26 15.5583 154.947C15.5583 154.947 52.3675 136.17 87.7968 106.862C123.488 77.6546 135.317 49.8385 143.508 50.3148Z"
      fill="#EDFAFF"
    />
    <path
      d="M15.5739 155.077C15.5739 155.077 37.7051 124.449 48.8061 92.6268C63.4949 51.0025 64.4618 20.2556 66.0949 17.1563C67.7279 14.0569 77.1879 19.9275 92.327 29.3464C103.006 35.996 112.885 45.3816 112.885 45.3816C112.885 45.3816 57.2406 134.004 15.5739 155.077Z"
      fill="#EDFAFF"
    />
    <path
      d="M154.618 55.3284C177.651 58.5255 204.225 50.4739 198.893 59.2953C193.562 68.1166 137.851 125.72 50.6145 144.564C50.6145 144.564 125.333 115.196 156.893 89.1221C193.926 58.5669 179.164 60.7218 172.562 59.9242C160.498 58.4569 154.618 55.3284 154.618 55.3284Z"
      fill="white"
    />
    <path
      d="M27.229 144.71C27.229 144.71 81.4909 88.6001 106.484 47.3324C106.484 47.3324 92.1894 31.3432 75.8643 24.5742C75.8643 24.5742 89.8935 35.1817 94.7884 42.6524C96.9503 46.0918 94.4105 54.1844 89.4647 63.0918C77.5543 83.9199 27.229 144.71 27.229 144.71Z"
      fill="white"
    />
  </svg>
);
