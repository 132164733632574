import React from "react";
import styled from "styled-components";

const AboutUsFooterElement = ({ src, iconStyle, text }) => {
  return (
    <AboutUsFooterElementWrapper>
      <CircleIconWrapper>
        <img src={src} style={iconStyle} />
      </CircleIconWrapper>
      <TextUnderIcon>{text}</TextUnderIcon>
    </AboutUsFooterElementWrapper>
  );
};

export default AboutUsFooterElement;

const AboutUsFooterElementWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  align-items: center;
`;

const CircleIconWrapper = styled.div`
  border-radius: 50%;
  background: #ffffff;

  width: 2.5rem;
  height: 2.5rem;
  padding: 1rem;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
`;

const TextUnderIcon = styled.div`
  text-align: center;
  font-size: 14px;
`;
