import React from "react";
import styled from "styled-components";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { BrowserView, MobileView } from "react-device-detect";

import FooterContent from "./FooterContent";

import VkIcon from "../../../icons/icon-vk";
import InstIcon from "../../../icons/icon-inst";
import RightPlane from "../../../icons/right-plane";

import URLS from "../../urls";

const ContactsPage = () => {
  return (
    <ContactsPageWrapper id="contacts">
      <InfoBlock>
        <MobileView>
          <YMaps>
            <Map
              defaultState={{ center: [52.419975, 30.96597], zoom: 16 }}
              width={400}
              height={320}
            >
              <Placemark defaultGeometry={[52.419975, 30.96597]} />
            </Map>
          </YMaps>
        </MobileView>
        <BrowserView>
          <YMaps>
            <Map
              defaultState={{ center: [52.419975, 30.96597], zoom: 16 }}
              width={600}
              height={420}
            >
              <Placemark defaultGeometry={[52.419975, 30.96597]} />
            </Map>
          </YMaps>
        </BrowserView>
        <ContactsInfoBlock>
          <ContactsInfoBlockHeader>
            Контакты
            <div style={{ position: "relative" }}>
              <PlaneWrapper>
                <RightPlane width={150} height={100} />
              </PlaneWrapper>
            </div>
          </ContactsInfoBlockHeader>
          <div>
            <ContactsLabel>Адрес развивательного центра:</ContactsLabel>
            <Address>г.Гомель, ул. Барыкина, 153</Address>
          </div>
          <div>
            <ContactsLabel>Телефон:</ContactsLabel>
            <PhoneNumber>+375 29 555-55-55</PhoneNumber>
          </div>
          <div>
            <ContactsLabel>Мы в социальных сетях:</ContactsLabel>
            <SocialNetworks>
              <a href={URLS.VK} target="_blank">
                <VkIcon fill={"rgba(0, 0, 0, 0.54)"} />
              </a>
              <a href={URLS.INST} target="_blank">
                <InstIcon fill={"#6202EE"} />
              </a>
            </SocialNetworks>
          </div>
        </ContactsInfoBlock>
      </InfoBlock>
      <ContactsFooter>
        <FooterContent />
      </ContactsFooter>
    </ContactsPageWrapper>
  );
};

export default ContactsPage;

const ContactsPageWrapper = styled.div`
  display: grid;
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 3rem 5rem;
  @media (max-width: 1048px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContactsInfoBlock = styled.div`
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5rem;
  margin-top: 2rem;
}
`;

const ContactsInfoBlockHeader = styled.div`
  font-size: 36px;
  line-height: 42px;
`;

const ContactsFooter = styled.footer`
  grid-row: 4 / 5;
  background: #7f22fd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
`;

const SocialNetworks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60px;

  a:hover {
    cursor: pointer;
  }
`;

const ContactsLabel = styled.div`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
`;

const Address = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const PhoneNumber = styled.div`
  font-weight: 700;
  color: #4b01d1;
  font-size: 18px;
`;

const PlaneWrapper = styled.div`
  position: absolute;
  top: -100px;
  left: 150px;
`;
