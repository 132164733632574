import React from "react";
import styled from "styled-components";

import LandingPage from "./pages/landing";
import AboutUsPage from "./pages/about-us";
import ServicesPage from "./pages/services";
import ContactsPage from "./pages/contacts";

import backgroundSvg from "../icons/background-boy.svg";

const Theme = () => {
  return (
    <>
      {/* <Title /> */}
      <Background>
        <LandingPage />
      </Background>
      <AboutUsPage />
      <ServicesPage />
      <ContactsPage />
    </>
  );
};

export default Theme;

const Background = styled.div`
  display: flex;
  background-image: url(${backgroundSvg});
  min-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 0px 20px;
`;
