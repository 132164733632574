const VK = "https://vk.com/maxiumby";
const INST = "https://www.instagram.com/maxium_gomel/?hl=ru";

const WP_API_URL = "https://admin.maksium.by/wp-admin/admin-ajax.php";
const GET_SERVICES = `${WP_API_URL}?action=sendServices`;
const GET_TEACHERS = `${WP_API_URL}?action=sendTeachers`;

export { VK, INST, WP_API_URL, GET_SERVICES, GET_TEACHERS };

export default {
  VK,
  INST,
  WP_API_URL,
  GET_SERVICES,
  GET_TEACHERS,
};
