import React from "react";
import styled from "styled-components";
import Navigation from "../../common/navigation";

import logoSvg from "../../../icons/logo.svg";

const Header = () => {
  return (
    <HeadContainer>
      <Logo>
        <img src={logoSvg} />
      </Logo>
      <Navigation />
      <Phone>+375 44 522 22 32</Phone>
    </HeadContainer>
  );
};

// Connect the Header component to get access to the `state` in it's `props`
export default Header;

const HeadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;

  @media only screen and (max-width: 600px) {
    padding: 0px;
  }
`;

const Logo = styled.div`
  flex-grow: 2;
`;

const Navbar = styled.div`
  flex-grow: 3;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  a {
    color: white;
    text-decoration: none;
  }
`;

const Phone = styled.div`
  flex-grow: 5;
  display: flex;
  justify-content: flex-end;
  color: #7f22fd;
  font-size: 16px;
  font-weight: bold;
`;
