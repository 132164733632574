import map from "lodash/map";
import find from "lodash/find";

import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

import URLS from "../../urls";

const TeachersBlock = () => {
  const [teachers, setTeachers] = useState([]);
  const [active, setActive] = useState();

  const onClickImg = (activeImage) => {
    setActive(activeImage);
  };

  useEffect(() => {
    fetch(URLS.GET_TEACHERS)
      .then((response) => response.json())
      .then((data) => {
        setTeachers(data);
        data[0] && setActive(data[0].ID);
      });
  }, []);

  const activeTeacher =
    find(teachers, (teacher) => teacher.ID === active) ||
    (teachers && teachers[0]);
  const [surname, name] = activeTeacher
    ? activeTeacher.NAME.replace(/\s/, "&").split("&")
    : ["", ""];

  return (
    <TeachersBlockWrapper>
      <TeachersBlockInnerWrapper>
        {map(teachers, (teacher) => (
          <TeacherImg
            key={teacher.ID}
            src={teacher.IMG_URL}
            active={active === teacher.ID}
            onClick={() => onClickImg(teacher.ID)}
          />
        ))}

        {activeTeacher && (
          <TeacherInfoBlock key={active}>
            <Row>
              <Surname>{surname}</Surname>
              <Name>{name}</Name>
            </Row>
            <Row>
              <TeacherInfo
                dangerouslySetInnerHTML={{ __html: activeTeacher.CONTENT }}
              />
            </Row>
          </TeacherInfoBlock>
        )}
      </TeachersBlockInnerWrapper>
    </TeachersBlockWrapper>
  );
};

export default TeachersBlock;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInImg = keyframes`
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
`;

const TeachersBlockWrapper = styled.div`
  max-width: 750px;
  background: #7f22fd;
  align-self: center;
  grid-column: 1;
  grid-row: 1/7;
`;

const TeachersBlockInnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, auto);

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

const TeacherImg = styled.img`
  width: 150px;
  height: 150px;
  opacity: ${(props) => (props.active ? "1" : "0.6")};
  &:hover {
    animation: ${(props) => (props.active ? "none" : css`0.4s ${fadeInImg}`)};
    opacity: 1;
    cursor: pointer;
  }
`;

const Surname = styled.div`
  font-size: 36px;
  line-height: 40px;
  font-weight: 300;
  padding: 20px 0px 10px 0px;
`;

const Name = styled.div`
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.25px;
  padding-bottom: 30px;
`;

const TeacherInfoBlock = styled.div`
  grid-column: 3 / 5;
  grid-row: 1 / 3;
  align-self: center;
  justify-self: center;

  animation: 1s ${fadeIn};

  @media only screen and (max-width: 600px) {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }
}
`;

const TeacherInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 20px;
  white-space: pre-line;
`;
