import React from "react";
import styled from "styled-components";

import TeachersBlock from "./teachers-block";
import AboutUsFooterElement from "./about-us-footer-element";

import aboutUsIcon from "../../../icons/about-us.svg";
import manWomanIcon from "../../../icons/man-woman.svg";
import neuronalIcon from "../../../icons/neuronal.svg";
import searchUserIcon from "../../../icons/search-user.svg";
import scheduleIcon from "../../../icons/schedule.svg";
import graphicIcon from "../../../icons/graphic.svg";
import planeIcon from "../../../icons/plane.svg";

const AboutUsPage = () => {
  return (
    <AboutUsPageWrapper id="about-us">
      <AboutUsPageHeader>
        <AboutUsImg src={aboutUsIcon} />
        <AboutUsPageHeaderText>
          <AboutUs>О нас</AboutUs>
          <AboutUsText>
            &nbsp;&nbsp;&nbsp;&nbsp;Самое ценное в жизни - это наши дети!
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;В центре "МаксиУм" вы доверите своего
            ребёнка <SpanBold>профессионалам</SpanBold>, понимающим и
            раскрывающим потенциал каждого ученика. Преподаватели нашего центра
            - это педагоги высшей категории с большим опытом работы,
            разрабытывающие уникальные авторские программы и курсы.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Ученье с увлечением! И не только. Наш центр
            располагает обширной материальной базой, современными средствами
            обучения, комфортными условиями для занятий с соблюдением санитарных
            норм и питьевого режима. Учебные кабинеты оснащены компьютерами,
            телевизорами, оборудованы растущей мебелью. Уютная зона отдыха для
            родителей поможет приятно провести время ожидания.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Команда "МаксиУм" - это коллектив творческих
            педагогов - единомышленников, профессионалов высокого класса. Что
            нас объединяет? Мы любим и умеем работать с детьми. Мы делаем всё,
            чтобы учебный процесс был результативным и творческим!
            <br />
            <SpanBold>
              &nbsp;&nbsp;&nbsp;&nbsp;Умные дети - успешное будущее!
            </SpanBold>
          </AboutUsText>
        </AboutUsPageHeaderText>
      </AboutUsPageHeader>
      <AboutUsPageContent>
        <TeachersBlock />
        <AboutUsPageFooter>
          <PlaneIconImg src={planeIcon} />
          <AboutUsFooterIcons>
            <AboutUsFooterElement
              src={manWomanIcon}
              style={{ marginTop: "5px" }}
              text={
                <span>
                  Высококвалифицированные <br /> специалисты
                </span>
              }
            />
            <AboutUsFooterElement
              src={neuronalIcon}
              text={
                <span>
                  Современные методики <br /> обучения
                </span>
              }
            />
            <AboutUsFooterElement
              src={searchUserIcon}
              text={
                <span>
                  Индивидуальный <br /> подход
                </span>
              }
            />
            <AboutUsFooterElement
              src={scheduleIcon}
              text={
                <span>
                  Удобный график и <br /> возможность дистанционного <br />
                  обучения
                </span>
              }
            />
            <AboutUsFooterElement
              src={graphicIcon}
              text={
                <span>
                  Высокие гарантированные <br />
                  результаты
                </span>
              }
            />
          </AboutUsFooterIcons>
        </AboutUsPageFooter>
      </AboutUsPageContent>
    </AboutUsPageWrapper>
  );
};

export default AboutUsPage;

const AboutUsImg = styled.img`
  max-height: 300px;
  @media only screen and (max-width: 600px) {
    max-height: 200px;
  }
`;

const AboutUsPageWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

const AboutUsPageHeader = styled.div`
  padding: 0px 3rem 1rem;
  & > img {
    float: left;
  }
`;

const AboutUsPageHeaderText = styled.div`
  grid-column: 2 / 6;
`;

const AboutUsPageContent = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-rows: repeat(5, 1fr);
`;

const AboutUsPageFooter = styled.div`
  position: relative;
  background-color: #ffcb00;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 200px;
  grid-column: 1;
  grid-row: 6/12;
  z-index: -1;
  align-self: end;
`;

const AboutUs = styled.div`
  padding: 20px;
  font-size: 28px;
  font-weight: 600;
`;

const SpanBold = styled.span`
  font-weight: 700;
`;

const AboutUsText = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

const AboutUsFooterIcons = styled.div`
  align-self: flex-end;
  flex: 1;
  padding-left: 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
  align-items: baseline;
  margin-top: 70px;
`;

const PlaneIconImg = styled.img`
  width: 6rem;
  position: absolute;
`;

const FooterPlane = styled.div``;
