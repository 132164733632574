import React from "react";

const Link = ({
  link,
  className,
  children,
  "aria-current": ariaCurrent,
  ...other
}) => {
  return (
    <a
      href={`${link}`}
      className={className}
      aria-current={ariaCurrent}
      {...other}
    >
      {children}
    </a>
  );
};

export default Link;
