import React from "react";

export default (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0517 0.405304C13.7075 0.405304 15.3633 0.405304 17.019 0.405304C18.8818 0.405304 20.5375 1.1297 21.8829 2.47501C22.9177 3.50987 23.5386 4.75169 23.7456 6.20049C23.7456 6.51095 23.7456 6.8214 23.7456 7.13186C23.7456 10.4434 23.7456 13.6514 23.7456 16.963C23.7456 19.2397 22.8142 21.1024 20.9515 22.4477C20.0201 23.1721 18.9853 23.586 17.8469 23.6895C17.5365 23.6895 17.3295 23.793 17.019 23.793C13.7075 23.793 10.396 23.793 6.98095 23.793C5.42867 23.793 3.97987 23.2756 2.84153 22.3442C1.49622 21.2059 0.668336 19.7571 0.35788 18.1013C0.254395 17.7909 0.254395 17.3769 0.254395 17.0665C0.254395 13.7549 0.254395 10.5469 0.254395 7.23534C0.254395 4.54472 1.80668 2.06107 4.39381 1.02622C5.2217 0.508789 6.15307 0.405304 7.18792 0.405304C8.7402 0.405304 10.396 0.405304 12.0517 0.405304Z"
      fill="#BB86FC"
      {...props}
    />
    <path
      d="M12.0517 18.5368C15.5345 18.5368 18.4894 15.688 18.4894 12.0992C18.4894 8.61781 15.642 5.66156 12.1552 5.66156C8.57036 5.66156 5.61414 8.509 5.61414 12.0992C5.61414 15.5832 8.56769 18.5368 12.0517 18.5368ZM7.62339 12.0992C7.62339 9.58292 9.63527 7.67081 12.0517 7.67081C14.461 7.67081 16.3779 9.67952 16.4801 12.1018C16.4787 14.6167 14.4674 16.5275 12.0517 16.5275C9.5355 16.5275 7.62339 14.5157 7.62339 12.0992Z"
      fill="white"
      stroke="white"
      strokeWidth="0.25"
    />
    <path
      d="M19.1922 5.91157C19.7756 5.91157 20.2486 5.43861 20.2486 4.8552C20.2486 4.27178 19.7756 3.79883 19.1922 3.79883C18.6088 3.79883 18.1359 4.27178 18.1359 4.8552C18.1359 5.43861 18.6088 5.91157 19.1922 5.91157Z"
      fill="white"
      stroke="white"
      strokeWidth="0.25"
    />
  </svg>
);
