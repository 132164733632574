import React from "react";
import styled from "styled-components";
import Link from "./link";

const Navigation = ({ style }) => {
  return (
    <Navbar style={style}>
      {/* <a href={`#about-us`}>О нас</a> */}
      <Link link={`#about-us`}>О нас</Link>
      <Link link={`#services`}>Услуги</Link>
      {/* <Link link={`#galery`}>Галерея</Link> */}
      {/* <Link link={`#news`}>Новости</Link> */}
      <Link link={`#contacts`}>Контакты</Link>
    </Navbar>
  );
};

// Connect the Header component to get access to the `state` in it's `props`
export default Navigation;

const Navbar = styled.div`
  flex-grow: 3;
  font-size: 16px;
  display: flex;
  justify-content: space-around;
  a {
    color: white;
    text-decoration: none;
  }
`;
