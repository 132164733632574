import React from "react";
import styled from "styled-components";

import Modal from "../../common/modal";
// import aboutUsIcon from "../../../icons/about-us.svg";

const Card = ({ src, name, cardInfo }) => {
  return (
    <CardWrapper>
      <CardImg src={src} />
      <CardContent>
        <CardInfo>
          <CardName>{name}</CardName>
          <CardInfoText>{cardInfo}</CardInfoText>
        </CardInfo>
        <ButtonContainer>
          <Modal
            button={(props) => (
              <SubscribeButton {...props}>Записаться</SubscribeButton>
            )}
          />
          <MoreButton>Подробнее</MoreButton>
        </ButtonContainer>
      </CardContent>
    </CardWrapper>
  );
};

export default Card;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafa;
  max-width: 200px;
  height: 300px;
  box-shadow: 0px 1px 1px #c1c1c1;
`;

const CardImg = styled.img`
  width: 200px;
  height: 128px;
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 0.5rem;
`;

const CardInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
}`;

const CardName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 1rem;
`;

const CardInfoText = styled.div`
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 30px;
  justify-content: space-between;
`;

const SubscribeButton = styled.button`
  background: #7f22fd;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  border: 0px;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`;

const MoreButton = styled.button`
  background: none;
  color: #7f22fd;
  font-size: 14px;
  border: 0px;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`;
