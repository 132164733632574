import map from "lodash/map";

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Card from "./card";
import URLS from "../../urls";

const ServicesPage = ({ state }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch(URLS.GET_SERVICES)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
      });
  }, []);

  return (
    <ServicesPageWrapper id="services">
      <ServicesHeader>Услуги</ServicesHeader>
      <ServicesCardWrapper>
        {map(services, (service) => (
          <Card
            key={service.ID}
            src={service.IMG_URL}
            name={service.NAME}
            cardInfo={service.CONTENT}
          />
        ))}
      </ServicesCardWrapper>
    </ServicesPageWrapper>
  );
};

export default ServicesPage;

const ServicesPageWrapper = styled.div`
  justify-items: center;
  grid-template-rows: 1fr 9fr;
  padding: 0 1rem;
`;

const ServicesCardWrapper = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  // grid-template-rows: repeat(2, 1fr);
  // grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  @media (min-width: 1500px) {
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
  }
`;

const ServicesHeader = styled.div`
  text-align: center;
  color: #000000;
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
  padding: 20px;
`;
