import React from "react";
import styled from "styled-components";

import Modal from "../../common/modal";

import VkIcon from "../../../icons/icon-vk";
import InstIcon from "../../../icons/icon-inst";

import URLS from "../../urls";

const Header = () => {
  return (
    <MainInfoContainer>
      <InfoTextWrapper>
        <WhiteH3>Центр развития интеллекта</WhiteH3>
        <H1>Доверьте своих</H1>
        <H1>детей профессионалам</H1>
        <PurpleH3>и спокойно занимайтесь своими делами</PurpleH3>
      </InfoTextWrapper>
      <InfoButtonWrapper>
        <Modal button={(props) => <Button {...props}>Записаться</Button>} />
      </InfoButtonWrapper>
      <Subscribe>
        <div>Подписывайтесь в соцсетях</div>
        <SocialNetworks>
          <a href={URLS.VK} target="_blank">
            <VkIcon />
          </a>
          <a href={URLS.INST} target="_blank">
            <InstIcon />
          </a>
        </SocialNetworks>
      </Subscribe>
    </MainInfoContainer>
  );
};

// Connect the Header component to get access to the `state` in it's `props`
export default Header;

const InfoTextWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const InfoButtonWrapper = styled.div`
  flex: 1;
`;

const MainInfoContainer = styled.div`
  flex: 1;
  padding: 20px 40px;
  display: flex;
  text-transform: uppercase;
  // align-items: center;
  flex-direction: column;
  justify-content: space-between;
  // background-color: #1f38c5;
`;

const WhiteH3 = styled.div`
  font-size: 22px;
  line-height: 35px;
  color: #f2e7fe;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

const H1 = styled.div`
  font-weight: 600;
  font-size: 60px;
  line-height: 82px;
  letter-spacing: -1.5px;
  color: #701fd8;

  @media only screen and (max-width: 600px) {
    font-size: 34px;
    line-height: 48px;
  }
`;

const PurpleH3 = styled.div`
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: #701fd8;
`;

const Button = styled.button`
  background: #ffcb00;
  border-radius: 38px;
  border: 0px;
  padding: 20px 60px;
  width: fit-content;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  &:hover {
    cursor: pointer;
    color: #701fd8;
  }
  &:focus {
    outline: 0;
  }
`;

const Subscribe = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.5px;

  height: 60px;
  justify-content: space-between;

  color: #bb86fc;
  font-size: 16px;
  font-weight: 600;
`;

const SocialNetworks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60px;

  a:hover {
    cursor: pointer;
  }
`;
