import React from "react";
import styled from "styled-components";

import Navigation from "../../common/navigation";

import logoIcon from "../../../icons/logo.svg";

const FooterContent = () => {
  return (
    <FooterWrapper>
      <LogoWrapper>
        <Logo src={logoIcon} />
        <div style={{ padding: "5px 10px" }}>
          <p>
            Республика Беларусь, г. Гомель, <br />
            ул. Барыкина, 153
          </p>
          <p>ООО “МаксиУм” УНП 491333242</p>
        </div>
      </LogoWrapper>
      <div>
        <CallUsText>Звоните нам</CallUsText>
        <PhoneNumber>+375 44 522 22 32</PhoneNumber>
      </div>
      <Navigation style={{ width: "100%", height: "fit-content" }} />
    </FooterWrapper>
  );
};

export default FooterContent;

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  color: #ffffff;
  font-size: 14px;
  justify-items: center;
  padding: 0.8rem;
`;

const LogoWrapper = styled.div`
  display: flex;
`;

const Logo = styled.img`
  height: 100px;
`;

const CallUsText = styled.div`
  font-size: 24px;
  line-height: 36px;
`;

const PhoneNumber = styled.div`
  font-size: 36px;
  line-height: 42px;
`;
